import * as React from "react";
import { SVGProps } from "react";

const IconCutProfileHorizontal = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={16}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1 12V4h14v8H1Z" fill="#1234B0" />
  </svg>
);

export default IconCutProfileHorizontal;
