import * as React from "react";
import { SVGProps } from "react";

const IconGears = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="m21.097 19.82 3.54 2.04 1-1.74-3.52-2.04c.56-1.26.9-2.64.9-4.1 0-1.46-.34-2.84-.9-4.1l3.52-2.04-1-1.74-3.54 2.04a10 10 0 0 0-7.1-4.08V0h-2v4.1a10 10 0 0 0-7.1 4.08l-3.54-2.04-1 1.74 3.52 2.04c-.56 1.26-.9 2.64-.9 4.1 0 1.46.34 2.84.9 4.1l-3.5 2 1 1.74 3.54-2.04a10 10 0 0 0 7.1 4.08V28h2v-4.1a10 10 0 0 0 7.1-4.08h-.02Zm-8.1 2.18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8Zm32 12c0-1.46-.34-2.84-.9-4.1l3.52-2.04-1-1.74-3.54 2.04a10 10 0 0 0-7.1-4.08v-4.1h-2v4.1a10 10 0 0 0-7.1 4.08l-3.54-2.04-1 1.74 3.52 2.04c-.56 1.26-.9 2.64-.9 4.1 0 1.46.34 2.84.9 4.1l-3.52 2.04 1 1.74 3.54-2.04a10 10 0 0 0 7.1 4.08v4.1h2v-4.1a10 10 0 0 0 7.1-4.08l3.54 2.04 1-1.74-3.52-2.04c.56-1.26.9-2.64.9-4.1Zm-10 8c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path d="M0 0h48v48H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);

export default IconGears;
