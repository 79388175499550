import * as React from "react";
import { SVGProps } from "react";

const IconCutProfileCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={16}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 13a4.89 4.89 0 0 1-1.941-.388 5.095 5.095 0 0 1-1.596-1.075 5.163 5.163 0 0 1-1.08-1.596A4.811 4.811 0 0 1 3 8a4.926 4.926 0 0 1 .393-1.941 5.106 5.106 0 0 1 2.67-2.67A4.856 4.856 0 0 1 8 3a4.933 4.933 0 0 1 3.532 1.468 5.04 5.04 0 0 1 1.075 1.59c.259.604.39 1.25.393 1.942a4.81 4.81 0 0 1-.384 1.941 5.024 5.024 0 0 1-2.67 2.67A4.901 4.901 0 0 1 8 13Z"
      fill="#1234B0"
    />
  </svg>
);

export default IconCutProfileCircle;
