import * as React from "react";
import { SVGProps } from "react";

const IconPuzzle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M48 5c0-2.758-2.244-5-5-5H28v8.92l1.292-.392C30.482 8.164 31.53 8 32 8c1.102 0 2 .896 2 2s-.898 2-2 2c-.47 0-1.518-.164-2.708-.528L28 11.08V20h6.272c-.172.778-.272 1.488-.272 2 0 2.206 1.794 4 4 4s4-1.794 4-4c0-.512-.1-1.222-.272-2H48V5Zm-2 13h-6.918l.392 1.292C39.836 20.482 40 21.53 40 22c0 1.104-.898 2-2 2-1.102 0-2-.896-2-2 0-.47.166-1.52.526-2.708L36.918 18H30v-4.274c.778.174 1.49.274 2 .274 2.206 0 4-1.794 4-4s-1.794-4-4-4c-.51 0-1.222.1-2 .274V2h13c1.654 0 3 1.346 3 3v13ZM29.474 29.292C29.836 30.482 30 31.53 30 32c0 1.104-.898 2-2 2-1.102 0-2-.896-2-2 0-.47.166-1.52.526-2.708L26.918 28H20V10H5c-2.756 0-5 2.242-5 5v33h38V28h-8.918l.392 1.292ZM2 15c0-1.654 1.346-3 3-3h13v16h-4.272c.172-.778.272-1.488.272-2 0-2.206-1.794-4-4-4s-4 1.794-4 4c0 .512.1 1.222.272 2H2V15Zm16 31H2V30h6.918l-.392-1.292C8.164 27.518 8 26.47 8 26c0-1.104.898-2 2-2 1.102 0 2 .896 2 2 0 .47-.166 1.52-.526 2.708L11.082 30H18v4.274C17.222 34.1 16.51 34 16 34c-2.206 0-4 1.794-4 4s1.794 4 4 4c.51 0 1.222-.1 2-.274V46Zm13.728-16H36v16H20v-6.92l-1.292.392C17.518 39.836 16.47 40 16 40c-1.102 0-2-.896-2-2s.898-2 2-2c.47 0 1.518.164 2.708.528L20 36.92V30h4.272c-.172.778-.272 1.488-.272 2 0 2.206 1.794 4 4 4s4-1.794 4-4c0-.512-.1-1.222-.272-2Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path d="M0 0h48v48H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);

export default IconPuzzle;
