import * as React from "react";
import { SVGProps } from "react";

const IconQuestion = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={20}
    width={20}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.938 14.063a.937.937 0 1 1-1.875 0 .937.937 0 0 1 1.874 0ZM10 5.624c-1.723 0-3.125 1.262-3.125 2.813v.312a.625.625 0 0 0 1.25 0v-.313c0-.859.841-1.562 1.875-1.562s1.875.703 1.875 1.563C11.875 9.296 11.034 10 10 10a.625.625 0 0 0-.625.625v.625a.625.625 0 1 0 1.25 0v-.056c1.425-.262 2.5-1.399 2.5-2.757 0-1.55-1.402-2.812-3.125-2.812ZM18.125 10A8.125 8.125 0 1 1 10 1.875 8.133 8.133 0 0 1 18.125 10Zm-1.25 0A6.875 6.875 0 1 0 10 16.875 6.883 6.883 0 0 0 16.875 10Z"
      fill="#1234B0"
    />
  </svg>
);

export default IconQuestion;
