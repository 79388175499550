import * as React from "react";
import { SVGProps } from "react";

const IconProcess = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M24 20c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4Zm0 6c-1.102 0-2-.898-2-2 0-1.102.898-2 2-2 1.102 0 2 .898 2 2 0 1.102-.898 2-2 2Zm10.578-11.65-3.874 2.26a9.904 9.904 0 0 0-3.706-2.134V10h-6v4.476a9.963 9.963 0 0 0-3.706 2.136l-3.872-2.26-3.026 5.182 3.882 2.266a9.23 9.23 0 0 0-.28 2.202c0 .706.092 1.43.28 2.2l-3.882 2.268 3.026 5.182 3.872-2.262a9.924 9.924 0 0 0 3.706 2.136v4.476h6v-4.476a9.909 9.909 0 0 0 3.706-2.136l3.874 2.262 3.024-5.182-3.88-2.266c.188-.768.28-1.496.28-2.202a9.23 9.23 0 0 0-.28-2.202l3.88-2.266-3.024-5.184Zm-3.222 6.512.24.76c.276.878.404 1.634.404 2.376s-.128 1.498-.404 2.374l-.24.762 3.512 2.05-1.008 1.726-3.494-2.04-.544.582a7.958 7.958 0 0 1-4.042 2.33l-.778.176v4.038h-2v-4.038l-.778-.176a7.942 7.942 0 0 1-4.042-2.33l-.544-.582-3.494 2.04-1.008-1.726 3.51-2.05-.238-.76c-.276-.878-.404-1.634-.404-2.376s.128-1.496.404-2.376l.238-.76-3.51-2.048 1.008-1.726 3.494 2.04.546-.582a7.95 7.95 0 0 1 4.042-2.33l.778-.176v-4.038h2v4.038l.778.176a7.95 7.95 0 0 1 4.042 2.33l.544.582 3.494-2.04 1.008 1.726-3.512 2.05-.002-.002ZM48 23.998c0 13.234-10.766 24-24 24-6.98 0-13.438-2.982-18-8.182v8.182H4v-9.166a2.837 2.837 0 0 1 2.834-2.834H16v2H7.068c4.2 5.08 10.312 8 16.932 8 12.13 0 22-9.87 22-22h2Zm-46 0H0C0 10.766 10.766 0 24 0c6.98 0 13.438 2.982 18 8.182V0h2v9c0 1.654-1.346 3-3 3h-9v-2h8.932C36.734 4.92 30.62 2 24 2 11.87 2 2 11.87 2 24v-.002Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path d="M0 0h48v48H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);

export default IconProcess;
