import * as React from "react";
import { SVGProps } from "react";

const IconHrPerson = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M23.998 10c2.758 0 5-2.244 5-5s-2.242-5-5-5-5 2.244-5 5 2.242 5 5 5Zm0-8c1.654 0 3 1.346 3 3s-1.346 3-3 3-3-1.346-3-3 1.346-3 3-3Zm24 9v26.462a5.006 5.006 0 0 1-1.29 3.352L40.314 48h-2.688l7.598-8.526a3 3 0 0 0 .774-2.012V11c0-1.654-1.346-3-3-3s-3 1.346-3 3v18h-.014c0 1.14-.38 2.28-1.154 3.202l-6.198 6.412-1.438-1.39 6.15-6.358a2.88 2.88 0 0 0 .652-1.914v-.02a3.022 3.022 0 0 0-.764-1.934 2.984 2.984 0 0 0-2.064-.992 3.042 3.042 0 0 0-2.16.76l-7.716 7.1c.442.758.704 1.616.704 2.508v11.628h-2V36.374c0-.836-.354-1.642-.968-2.208l-8.052-7.408c-.584-.526-1.35-.782-2.15-.75a2.972 2.972 0 0 0-2.064.992 3.004 3.004 0 0 0-.064 3.92l6.104 6.306-1.438 1.39-6.15-6.358a5.07 5.07 0 0 1-1.194-3.254H8V11c0-1.654-1.346-3-3-3s-3 1.346-3 3v26.462a3 3 0 0 0 .774 2.012L10.372 48H7.684L1.29 40.814A5.006 5.006 0 0 1 0 37.462V11c0-2.756 2.242-5 5-5s5 2.244 5 5v13.998a5 5 0 0 1 6.324.274L24 32.334l7.666-7.054c1.004-.9 2.284-1.362 3.616-1.274 1 .054 1.938.398 2.718.988V11c0-2.756 2.242-5 5-5a5.004 5.004 0 0 1 4.998 5Zm-30 10.572c-.636-.428-1.3-.796-2-1.044V17c0-2.756 2.242-5 5-5h6c2.758 0 5 2.244 5 5v3.538c-.7.248-1.362.612-2 1.04V17c0-1.656-1.344-3-3-3h-6c-1.656 0-3 1.344-3 3v4.572Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path d="M0 0h48v48H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);

export default IconHrPerson;
