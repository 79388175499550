import * as React from "react";
import { SVGProps } from "react";

const IconBarcode = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={20}
    width={20}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.125 3.75v3.125a.625.625 0 1 1-1.25 0v-2.5h-2.5a.625.625 0 1 1 0-1.25H17.5a.625.625 0 0 1 .625.625Zm-12.5 11.875h-2.5v-2.5a.625.625 0 1 0-1.25 0v3.125a.625.625 0 0 0 .625.625h3.125a.625.625 0 1 0 0-1.25ZM17.5 12.5a.624.624 0 0 0-.625.625v2.5h-2.5a.624.624 0 1 0 0 1.25H17.5a.624.624 0 0 0 .625-.625v-3.125a.624.624 0 0 0-.625-.625Zm-15-5a.625.625 0 0 0 .625-.625v-2.5h2.5a.625.625 0 0 0 0-1.25H2.5a.625.625 0 0 0-.625.625v3.125A.625.625 0 0 0 2.5 7.5Zm3.75-1.25a.625.625 0 0 0-.625.625v6.25a.625.625 0 1 0 1.25 0v-6.25a.625.625 0 0 0-.625-.625Zm8.125 6.875v-6.25a.625.625 0 1 0-1.25 0v6.25a.624.624 0 1 0 1.25 0ZM11.25 6.25a.625.625 0 0 0-.625.625v6.25a.624.624 0 1 0 1.25 0v-6.25a.625.625 0 0 0-.625-.625Zm-2.5 0a.625.625 0 0 0-.625.625v6.25a.625.625 0 1 0 1.25 0v-6.25a.625.625 0 0 0-.625-.625Z"
      fill="currentColor"
    />
  </svg>
);

export default IconBarcode;
