import * as React from "react";
import { SVGProps } from "react";

const IconChatText = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={20}
    width={20}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.875 3.75H3.125A1.25 1.25 0 0 0 1.875 5v12.5a1.238 1.238 0 0 0 .722 1.133 1.241 1.241 0 0 0 1.34-.187l2.508-2.196h10.43a1.25 1.25 0 0 0 1.25-1.25V5a1.25 1.25 0 0 0-1.25-1.25Zm0 11.25H6.445c-.294 0-.579.104-.804.293l-.01.009L3.125 17.5V5h13.75v10Zm-10-6.25a.625.625 0 0 1 .625-.625h5a.625.625 0 1 1 0 1.25h-5a.625.625 0 0 1-.625-.625Zm0 2.5a.625.625 0 0 1 .625-.625h5a.625.625 0 1 1 0 1.25h-5a.625.625 0 0 1-.625-.625Z"
      fill="currentColor"
    />
  </svg>
);

export default IconChatText;
