import * as React from "react";
import { SVGProps } from "react";

const IconBoss = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M23.998 19.999c5.514 0 10-4.486 10-10s-4.486-10-10-10-10 4.486-10 10 4.486 10 10 10Zm0-18c4.412 0 8 3.588 8 8s-3.588 8-8 8-8-3.588-8-8 3.588-8 8-8Zm24 38h-8v-9c0-4.962-4.038-9-9-9h-14c-4.962 0-9 4.038-9 9v9h-8v2h4v6h2v-6h36v6h2v-6h4v-2Zm-10-9v9h-9.22l-2.704-10.814 3.458-5.186h1.464c3.86 0 7.002 3.14 7.002 7Zm-14-2.302-3.132-4.698h6.264l-3.132 4.698Zm0 .424 2.72 10.878h-5.44l2.72-10.878Zm-14 1.878c0-3.86 3.14-7 7-7h1.464l3.458 5.186-2.704 10.814h-9.22l.002-9Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path d="M0 0h48v48H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);

export default IconBoss;
