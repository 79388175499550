import * as React from "react";
import { SVGProps } from "react";

const IconSize = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={20}
    width={32}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m22.623 1.643 6.99-.366.734 13.98-7.174-3.128.183 3.495-7.173-3.129.183 3.495-7.173-3.128.183 3.495-7.174-3.129-.55-10.486 6.991-.366 6.99-.366 6.99-.367Z"
      fill="#8D9DA6"
      fillRule="evenodd"
    />
  </svg>
);

export default IconSize;
