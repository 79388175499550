import * as React from "react";
import { SVGProps } from "react";

const IconCuts = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={20}
    width={35}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.429 2v16M17.429 2l11.313 11.314M17.429 2 6.115 13.314M17.429 2l13.856 8M17.429 2 3.572 10"
      stroke="#8D9DA6"
      strokeLinecap="round"
      strokeWidth={2}
    />
  </svg>
);

export default IconCuts;
