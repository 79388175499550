import * as React from "react";
import { SVGProps } from "react";

const IconShapeLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={322}
    width={432}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M42.945 161.768 303.198 11.516c41.063-23.706 93.579-9.639 117.287 31.426 23.707 41.068 9.635 93.577-31.427 117.288L128.805 310.485c-41.066 23.706-93.576 9.634-117.287-31.434-23.711-41.069-9.639-93.57 31.427-117.283z"
      fill="#1234B0"
    />
  </svg>
);

export default IconShapeLarge;
