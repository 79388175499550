import * as React from "react";
import { SVGProps } from "react";

const IconCubes = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M36.5 20.14V6.98L25.48.7c-.92-.52-2.06-.52-2.98 0l-11 6.28v13.14L0 26.7V41l11.02 6.3c.46.26.98.4 1.48.4s1.02-.14 1.48-.4L24 41.58l10.02 5.72c.46.26.98.4 1.48.4s1.02-.14 1.48-.4L48 41V26.7l-11.5-6.58v.02Zm-1 12.56-9.48-5.42 9.48-5.42 9.48 5.42-9.48 5.42Zm-23 0-9.48-5.42 9.48-5.42 9.48 5.42-9.48 5.42Zm1-23.42L23 14.7v10.84l-9.5-5.42V9.28ZM25 25.54V14.72l9.5-5.42v10.84L25 25.56v-.02ZM23.5 2.42c.3-.18.68-.18 1 0l8.98 5.14L24 12.98l-9.48-5.42 8.98-5.12v-.02ZM2 39.84V29l9.5 5.42v10.84L2 39.84Zm11.5 5.42V34.42L23 29v10.84l-9.5 5.42ZM25 39.84V29l9.5 5.42v10.84L25 39.84Zm11.5 5.42V34.42L46 29v10.84l-9.5 5.42Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path d="M0 0h48v48H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);

export default IconCubes;
