import * as React from "react";
import { SVGProps } from "react";

const IconEnvelope = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={20}
    width={20}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.5 3.75h-15a.625.625 0 0 0-.625.625V15a1.25 1.25 0 0 0 1.25 1.25h13.75a1.25 1.25 0 0 0 1.25-1.25V4.375a.625.625 0 0 0-.625-.625ZM15.893 5 10 10.402 4.107 5h11.786Zm.982 10H3.125V5.796l6.452 5.915a.625.625 0 0 0 .846 0l6.452-5.915V15Z"
      fill="currentColor"
    />
  </svg>
);

export default IconEnvelope;
