import * as React from "react";
import { SVGProps } from "react";

const IconHandHoldingBox = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M46.362 19.308a4.974 4.974 0 0 0-3.618-1.302 4.98 4.98 0 0 0-3.478 1.634l-7.464 8.206A5.295 5.295 0 0 0 26.718 24H5c-2.756 0-5 2.242-5 5v14c0 2.758 2.244 5 5 5h22.448l19.278-21.634c1.822-2.046 1.658-5.21-.366-7.056l.002-.002Zm-1.128 5.724-18.68 20.966H5c-1.654 0-3-1.346-3-3v-14c0-1.654 1.346-3 3-3h21.716A3.29 3.29 0 0 1 30 29.284c0 1.624-1.212 3.022-2.808 3.248L15.87 34.006l.258 1.984 11.334-1.476c2.044-.292 3.69-1.756 4.292-3.646l8.99-9.88a2.994 2.994 0 0 1 2.094-.984 3.005 3.005 0 0 1 2.176.784 3.033 3.033 0 0 1 .218 4.246l.002-.002ZM14 6h8v2h-8V6Zm16-2c0-2.206-1.794-4-4-4H10C7.794 0 6 1.794 6 4v16h24V4Zm-2 14H8V4c0-1.104.898-2 2-2h16c1.102 0 2 .896 2 2v14Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path d="M0 0h48v48H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);

export default IconHandHoldingBox;
