import * as React from "react";
import { SVGProps } from "react";

const IconLanguageDE = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    viewBox="0 0 24 24"
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect fill="currentColor" height={24} rx={12} width={24} />
    <g clipPath="url(#clip0_3476_1228)">
      <g clipPath="url(#clip1_3476_1228)">
        <path
          d="M-1.63623 15.4092H25.6365V22.2274H-1.63623V15.4092Z"
          fill="#FFCE00"
        />
        <path
          d="M-1.63623 1.77271H25.6365V8.59089H-1.63623V1.77271Z"
          fill="black"
        />
        <path
          d="M-1.63623 8.59082H25.6365V15.409H-1.63623V8.59082Z"
          fill="#DD0000"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_3476_1228">
        <rect fill="white" height={20} rx={10} width={20} x={2} y={2} />
      </clipPath>
      <clipPath id="clip1_3476_1228">
        <rect
          fill="white"
          height={20.4545}
          transform="translate(-1.63623 1.77271)"
          width={27.2727}
        />
      </clipPath>
    </defs>
  </svg>
);

export default IconLanguageDE;
