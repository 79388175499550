import * as React from "react";
import { SVGProps } from "react";

const IconTextbox = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={20}
    width={20}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.75 3.125a.625.625 0 0 0-.625.625V5h-6.25a1.25 1.25 0 0 0-1.25 1.25v7.5A1.25 1.25 0 0 0 1.875 15h6.25v1.25a.625.625 0 1 0 1.25 0V3.75a.625.625 0 0 0-.625-.625ZM1.875 13.75v-7.5h6.25v7.5h-6.25Zm17.5-7.5v7.5a1.25 1.25 0 0 1-1.25 1.25H11.25a.624.624 0 1 1 0-1.25h6.875v-7.5H11.25a.625.625 0 1 1 0-1.25h6.875a1.25 1.25 0 0 1 1.25 1.25Zm-12.5 2.5a.625.625 0 0 1-.625.625h-.625v1.875a.625.625 0 1 1-1.25 0V9.375H3.75a.625.625 0 0 1 0-1.25h2.5a.625.625 0 0 1 .625.625Z"
      fill="currentColor"
    />
  </svg>
);

export default IconTextbox;
