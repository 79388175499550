import * as React from "react";
import { SVGProps } from "react";

const IconCrossSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={20}
    width={21}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.567 15.183a.624.624 0 1 1-.884.884L10.5 10.884l-5.183 5.183a.626.626 0 0 1-.884-.884L9.617 10 4.433 4.817a.625.625 0 0 1 .884-.884L10.5 9.116l5.183-5.183a.626.626 0 0 1 .884.884L11.384 10l5.183 5.183Z"
      fill="#000"
    />
  </svg>
);

export default IconCrossSmall;
