import * as React from "react";
import { SVGProps } from "react";

const IconCurProfileVertical = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={16}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M4 15V1h8v14H4Z" fill="#1234B0" />
  </svg>
);

export default IconCurProfileVertical;
