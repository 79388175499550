import * as React from "react";
import { SVGProps } from "react";

export const IconShapeFirst = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={200}
    width={221}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      fill="currentColor"
      height={60}
      rx={30}
      transform="rotate(-30 0 -20.5)"
      width={165}
      y={-20.5}
    />
  </svg>
);

export const IconShapeSecond = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={200}
    width={221}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      fill="currentColor"
      height={120}
      rx={60}
      transform="rotate(-30 63 96)"
      width={330}
      x={63}
      y={96}
    />
  </svg>
);
