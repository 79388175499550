import * as React from "react";
import { SVGProps } from "react";

const IconChats = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={20}
    width={20}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.875 6.25h-2.5v-2.5a1.25 1.25 0 0 0-1.25-1.25h-10a1.25 1.25 0 0 0-1.25 1.25v10a.625.625 0 0 0 1.016.486l2.734-2.205v2.344a1.25 1.25 0 0 0 1.25 1.25h7.312l2.922 2.36a.625.625 0 0 0 .833-.043.624.624 0 0 0 .183-.442v-10a1.25 1.25 0 0 0-1.25-1.25ZM5.199 10.764l-2.074 1.677V3.75h10v6.875H5.592a.625.625 0 0 0-.393.14Zm11.676 5.427-2.074-1.677a.625.625 0 0 0-.39-.139H6.874v-2.5h6.25a1.25 1.25 0 0 0 1.25-1.25V7.5h2.5v8.691Z"
      fill="currentColor"
    />
  </svg>
);

export default IconChats;
