import * as React from "react";
import { SVGProps } from "react";

const IconLanguageEN = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    viewBox="0 0 24 24"
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect fill="currentColor" height={24} rx={12} width={24} />
    <g clipPath="url(#clip0_3476_1219)">
      <g clipPath="url(#clip1_3476_1219)">
        <path
          d="M-1.63623 1.77271H25.6365V22.2273H-1.63623V1.77271Z"
          fill="#012169"
        />
        <path
          d="M1.55979 1.77271L11.9575 9.48577L22.3126 1.77271H25.6365V4.41475L15.4092 12.0426L25.6365 19.6278V22.2273H22.2274L12.0001 14.5994L1.81547 22.2273H-1.63623V19.6704L8.54843 12.0852L-1.63623 4.49998V1.77271H1.55979Z"
          fill="white"
        />
        <path
          d="M16.432 13.7471L25.6365 20.5227V22.2273L14.0882 13.7471H16.432ZM8.59104 14.5994L8.84672 16.0909L0.664906 22.2273H-1.63623L8.59104 14.5994ZM25.6365 1.77271V1.90055L15.0257 9.91191L15.1109 8.03691L23.5058 1.77271H25.6365ZM-1.63623 1.77271L8.54843 9.27271H5.99161L-1.63623 3.56248V1.77271Z"
          fill="#C8102E"
        />
        <path
          d="M8.63366 1.77271V22.2273H15.4518V1.77271H8.63366ZM-1.63623 8.59089V15.4091H25.6365V8.59089H-1.63623Z"
          fill="white"
        />
        <path
          d="M-1.63623 9.99714V14.088H25.6365V9.99714H-1.63623ZM9.99729 1.77271V22.2273H14.0882V1.77271H9.99729Z"
          fill="#C8102E"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_3476_1219">
        <rect fill="white" height={20} rx={10} width={20} x={2} y={2} />
      </clipPath>
      <clipPath id="clip1_3476_1219">
        <rect
          fill="white"
          height={20.4545}
          transform="translate(-1.63623 1.77271)"
          width={27.2727}
        />
      </clipPath>
    </defs>
  </svg>
);

export default IconLanguageEN;
