import { tv } from "tailwind-variants";

export const title = tv({
  base: "tracking-tight font-semibold",
  variants: {
    color: {
      primary: "text-[#1234B0]",
      danger: "text-[#D7141A]",
      "gray-light": "text-gray-light",

      /*violet: "from-[#FF1CF7] to-[#b249f8]",
      yellow: "from-[#FF705B] to-[#FFB457]",
      blue: "from-[#5EA2EF] to-[#0072F5]",
      cyan: "from-[#00b7fa] to-[#01cfea]",
      green: "from-[#6FEE8D] to-[#17c964]",
      pink: "from-[#FF72E1] to-[#F54C7A]",
      foreground: "dark:from-[#FFFFFF] dark:to-[#4B4B4B]",*/
    },
    size: {
      h1: "text-3xl lg:text-4xl",
      h2: "text-xl lg:text-2xl",
      h3: "text-md lg:text-xl",
      h4: "text-lg",
      "h1-large": "text-4xl lg:text-5xl leading-tight",
    },
    inline: {
      true: "inline",
    },
    uppercase: {
      true: "uppercase",
    },
  },
  defaultVariants: {
    size: "h1",
  },
});

export const subtitle = tv({
  base: "w-full md:w-1/2 my-2 text-lg lg:text-xl text-default-600 block max-w-full",
  variants: {
    fullWidth: {
      true: "!w-full",
    },
  },
  defaultVariants: {
    fullWidth: true,
  },
});

export const bodyText = tv({
  base: "",
  variants: {
    color: {
      primary: "text-primary",
      "gray-light": "text-gray-light",
      "gray-ligher": "text-gray-ligher",
      "gray-darker": "text-gray-darker",
    },
    size: {
      xs: "text-xs",
      sm: "text-sm lg:text-sm",
      md: "text-base",
      lg: "text-lg",
      xl: "text-lg lg:text-xl leading-[1.4em]",
      "2xl": "text-xl lg:text-2xl",
      "3xl": "text-2xl lg:text-3xl font-semibold leading-[1.25em]",
      "4xl": "text-3xl xl:text-4xl font-semibold leading-[1.2em]",
    },
    underline: {
      true: "underline",
    },
    weight: {
      "600": "font-semibold",
      "700": "font-bold",
    },
  },
  defaultVariants: {
    size: "md",
  },
});

export const link = tv({
  base: "underline hover:no-underline",
  /*variants: {
    size: {
      sm: "text-sm",
      md: "text-base",
      lg: "text-lg",
    },
  },
  defaultVariants: {
    size: "md",
  },*/
});
