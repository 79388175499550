import * as React from "react";
import { SVGProps } from "react";

const IconChatCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.125 12a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0Zm-5.25-1.125a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25Zm8.25 0a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25ZM21.75 12a9.75 9.75 0 0 1-14.332 8.608l-3.193 1.064a1.5 1.5 0 0 1-1.897-1.897l1.064-3.192A9.75 9.75 0 1 1 21.75 12Zm-1.5 0a8.25 8.25 0 1 0-15.393 4.13.75.75 0 0 1 .062.614L3.75 20.25l3.506-1.169a.733.733 0 0 1 .612.062A8.25 8.25 0 0 0 20.25 12Z"
      fill="#fff"
    />
  </svg>
);

export default IconChatCircle;
