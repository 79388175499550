import * as React from "react";
import { SVGProps } from "react";

const IconArrowRightLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={32}
    width={32}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m27.707 16.708-9 9a1 1 0 0 1-1.415-1.415L24.587 17H5a1 1 0 0 1 0-2h19.586l-7.293-7.292a1 1 0 0 1 1.415-1.415l9 9a1 1 0 0 1 0 1.415Z"
      fill="#fff"
    />
  </svg>
);

export default IconArrowRightLarge;
