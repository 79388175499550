import * as React from "react";
import { SVGProps } from "react";

const IconListSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    width={17}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.543 8a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1 0-1h11a.5.5 0 0 1 .5.5Zm-11.5-3.5h11a.5.5 0 1 0 0-1h-11a.5.5 0 0 0 0 1Zm11 7h-11a.5.5 0 0 0 0 1h11a.5.5 0 0 0 0-1Z"
      fill="#fff"
    />
  </svg>
);

export default IconListSmall;
