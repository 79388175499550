import * as React from "react";
import { SVGProps } from "react";

const IconMapPinLine = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={20}
    width={20}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.625 17.5h-3.864c.649-.58 1.261-1.2 1.833-1.855 2.144-2.467 3.281-5.067 3.281-7.52a6.875 6.875 0 1 0-13.75 0c0 2.453 1.134 5.053 3.281 7.52A20.824 20.824 0 0 0 8.24 17.5H4.375a.625.625 0 1 0 0 1.25h11.25a.624.624 0 1 0 0-1.25ZM4.375 8.125a5.625 5.625 0 0 1 11.25 0c0 4.471-4.334 8.203-5.625 9.219-1.291-1.016-5.625-4.748-5.625-9.219Zm8.75 0a3.125 3.125 0 1 0-6.25 0 3.125 3.125 0 0 0 6.25 0Zm-5 0a1.875 1.875 0 1 1 3.75 0 1.875 1.875 0 0 1-3.75 0Z"
      fill="currentColor"
    />
  </svg>
);

export default IconMapPinLine;
