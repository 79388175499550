import * as React from "react";
import { SVGProps } from "react";

const IconCross = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={32}
    width={32}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M25.707 24.293a1 1 0 0 1-1.415 1.415L16 17.414l-8.292 8.294a1 1 0 0 1-1.415-1.415L14.586 16 6.293 7.708a1 1 0 1 1 1.415-1.415L16 14.586l8.293-8.293a1 1 0 1 1 1.415 1.415L17.413 16l8.293 8.293Z"
      fill="currentColor"
    />
  </svg>
);

export default IconCross;
