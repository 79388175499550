import * as React from "react";
import { SVGProps } from "react";

const IconShape = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={49}
    width={62}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      fill="#FDAC11"
      height={21.094}
      rx={10.547}
      transform="rotate(-30 .832 29.983)"
      width={58.008}
      x={0.832}
      y={29.983}
    />
  </svg>
);

export default IconShape;
