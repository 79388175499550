import * as React from "react";
import { SVGProps } from "react";

const IconList = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={32}
    width={32}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M28 16a1 1 0 0 1-1 1H5a1 1 0 0 1 0-2h22a1 1 0 0 1 1 1ZM5 9h22a1 1 0 1 0 0-2H5a1 1 0 0 0 0 2Zm22 14H5a1 1 0 0 0 0 2h22a1 1 0 0 0 0-2Z"
      fill="currentColor"
    />
  </svg>
);

export default IconList;
