import * as React from "react";
import { SVGProps } from "react";

const IconSuitecase = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={20}
    width={20}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.875 4.375H13.75V3.75a1.875 1.875 0 0 0-1.875-1.875h-3.75A1.875 1.875 0 0 0 6.25 3.75v.625H3.125a1.25 1.25 0 0 0-1.25 1.25v10a1.25 1.25 0 0 0 1.25 1.25h13.75a1.25 1.25 0 0 0 1.25-1.25v-10a1.25 1.25 0 0 0-1.25-1.25ZM7.5 3.75a.625.625 0 0 1 .625-.625h3.75a.625.625 0 0 1 .625.625v.625h-5V3.75Zm5 1.875v10h-5v-10h5Zm-9.375 0H6.25v10H3.125v-10Zm13.75 10H13.75v-10h3.125v10Z"
      fill="currentColor"
    />
  </svg>
);

export default IconSuitecase;
